import React from 'react';
import { Card, CardGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

function SlotList({slots}){


    const renderedSlots = slots.map((slot) => {

      return  <div><Card key={slot.id} className="ml-1 mt-1 custom-card" style={{ backgroundColor: 'white', height:'75px', fontSize:'14px', color:'grey' }}>
      <Card.Body>

      <FontAwesomeIcon icon={faClock} style={{textAlign:'center'}}/><Card.Title>{slot.time_slot}       
</Card.Title>
      </Card.Body>

    </Card>

    </div>


    });

    return <div>
  
   
        {renderedSlots}
  
    </div>


}

export default SlotList;