import axios from 'axios'

const timeSlots = async ()  => {

    const response = await axios.get('https://jsb-it-support.com/TimeSlots/');

    return response.data;


}

export default timeSlots 

