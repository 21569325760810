import { useState, useEffect  } from 'react'
import EventList from './components/EventList';
import Header from './components/Header';
import axios from "axios";
import timeSlots from './SlotsApi';
import ClubsList from './ClubsApi';

function App(){

  const initialSchedule = [];
  const today = new Date();
  
  const [schedule, setSchedule] = useState([]);
  const [eventTimeSlot, setEventTimeSlot] = useState([]);
  const [eventDate, setEventDate] = useState(today.toISOString().slice(0, 10));
  const [clubList, setClubList] = useState([]);
  const [clubId, setClubID] =  useState(1)


  const formatDate = (date) => {
    const localDate = new Date(date);
    return localDate.toISOString().slice(0, 10); // Format as YYYY-MM-DD
};



  useEffect(() => {
    const fetchTimeSlot = async () => {
      const timeSlotData = await timeSlots(); // Assuming timeSlots returns a Promise
      setEventTimeSlot(timeSlotData);
    };

    fetchTimeSlot();
  }, []);


useEffect(() => {
  const fetchclubs = async () => {

    const clubData = await ClubsList(); 
    setClubList(clubData);
};
  fetchclubs();
}, []);


const fetchSchedule = async () => {
  try {
    const response = await axios.get(`https://jsb-it-support.com/event_schedule?date=${eventDate}&clubid=${clubId}`);
    setSchedule(response.data);
  } catch (error) {
    console.error('Error fetching schedule:', error.message);
  }
};

useEffect(() => {
  fetchSchedule(eventDate, clubId);
}, [eventDate, clubId]);

const handleDateClubChange = (getselecteddate, selectedClubId) => {
      
  
      const selectedlocalDate = new Date(getselecteddate);
      selectedlocalDate.setDate(selectedlocalDate.getDate() + 1);
      setEventDate(formatDate(selectedlocalDate));
      setClubID(selectedClubId);
      fetchSchedule(formatDate(selectedlocalDate), selectedClubId);
};

const handleTitleChange = async (eventId, staffId, newTitle, typeId, EventDate) => {
        const jsonData = {
          clubid: clubId,
          slot_id: eventId,
          staff_id: staffId,
          event_type_id: typeId,
          event_title: newTitle,
          event_date: eventDate
        };

        console.log("posting date" + eventDate)

        try {
          const response = await axios.post('https://jsb-it-support.com/Event', jsonData);
        } catch (error) {
          if (error.response) {
            console.error('Error response from server:', error.response.data);
          } else {
            console.error('Error posting data:', error.message);
          }
        }


        fetchSchedule();

        
      };



      return (<div>
          
            
           <Header onDateChange={handleDateClubChange} clublist = {clubList} />
           <EventList events = {schedule} slots={eventTimeSlot} onTitleChange={handleTitleChange}/>
          
        </div>)

}

export default App;