import axios from 'axios'

const ClubsList = async ()  => {

    
    const response = await axios.get('https://jsb-it-support.com/Clubs/');

    return response.data;


}

export default ClubsList

