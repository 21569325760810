import React, { useState } from 'react';
import { Card, CardGroup } from 'react-bootstrap';
import './ShowEvent.css';
import { FaTrash, FaEdit, FaSquare } from 'react-icons/fa';
import EventCard from './EventCard';

function ShowEvent({ events, slots, memberId, onTitleChange}) {
      
      let content = '';
      
      const handleTitleChange = (staffId, eventId, title, typeid) => {


                  onTitleChange(staffId, eventId, title, typeid)
            
         
      }
      
      const renderedEvents = events.map((event, index) => {

            const cardStyle = {
                  backgroundColor: getColorBasedOnEventType(event.type_id),
                  height: '75px',
                  border: '1px solid #95a5a6'

                };
            
            
            content = <div key={event.id}><EventCard event={event} eventTitle={event.title} eventId={event.id} style={cardStyle} onTitleChange={handleTitleChange} memberId={memberId}/></div>
            
            return content

      });

      return <div>{renderedEvents}</div>


}



function getColorBasedOnEventType(type_id) {
  
      switch (type_id) {

        case 1:
          return '#000000'; 
        case 2:
          return '#FFFFFF'; 
        case 3:
          return '#34495e'; 
        case 4:
            return '#6c3483'; 
        case 5:
              return '#e67e22';     
        default:
          return '#FFFFFF'; // Default color
      }
    }

export default ShowEvent;
