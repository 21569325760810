import React from 'react';
import { Card, CardGroup } from 'react-bootstrap';
import SlotList from './SlotList';
import ShowEvent from './ShowEvent';
import EventCard from './EventCard';
import './EventList.css'; // Import your custom CSS for EventList styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function EventList({ events, slots, onTitleChange }) {

  const handleTitleChange = (eventId, staffId, title, typeid) => {
      onTitleChange(staffId, eventId, title, typeid);
  }
 
  const renderedEvents = events.map((event) => (
    <React.Fragment key={event.id}>
      <Card key={event.id} className="ml-1 mt-1 custom-card" style={{ backgroundColor: 'white' }}>
        <Card.Body>
          <Card.Title>{event.staff} <FontAwesomeIcon icon="fa-solid fa-circle-user" /></Card.Title>
          <ShowEvent events={event.events} slots={slots} onTitleChange={handleTitleChange} memberId={event.id} />
        </Card.Body>
      </Card>
    </React.Fragment>
  ));

  return (
    <div>
      <CardGroup className="event-list-container">
        <Card className="slot-list-card mt-1 custom-card">
          <Card.Body >
            <Card.Title>Staff / Time Slot</Card.Title>

 <SlotList slots={slots} /> 

          </Card.Body>

        </Card>

        {renderedEvents}

      </CardGroup>
    </div>
  );
}

export default EventList;
