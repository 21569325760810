import '../components/Header.css'
import React, { useState, useEffect } from 'react';
import { Col, Container, Navbar } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DropDown from './DropDown';
import SnapImage from '../snapweblogo.png'


function Header({onDateChange, clublist}){
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedClubId, setSelectedClubId] = useState(1);

const formatDate = (date) => {
    const localDate = new Date(date);
    return localDate.toISOString().slice(0, 10); // Format as YYYY-MM-DD
};

const handleClubChange = (clubId) => {
    setSelectedClubId(clubId);
    const newlocalDate = new Date(selectedDate);

    onDateChange(formatDate(newlocalDate), clubId); // Pass both date and club ID
};
  
const handleDateChange = (date) => {
  const localDate = new Date(date);
  setSelectedDate(localDate);
  onDateChange(formatDate(localDate), selectedClubId); // Pass both date and club ID
  setCalendarOpen(false);  

};


const toggleCalendar = () => {
    setCalendarOpen(!isCalendarOpen);
};


    return (
        <Container>
          <Navbar.Brand href="#" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <img src={SnapImage} alt="Logo" className="header-brand" /> Fitness Club Daily Rota
                <div style={{ width: '30px', height: '30px', backgroundColor: 'black', marginLeft: '30px', border: '2px solid grey' }}></div>
                <span style={{ marginLeft: '15px', color: 'black', fontSize: '12px' }}>Break/Workout</span>

                <div style={{ width: '30px', height: '30px', backgroundColor: '#FFFFFF', border: '1px solid grey', marginLeft: '30px' }}></div>
                <span style={{ marginLeft: '15px', color: 'black', fontSize: '12px' }}>Free</span>

                <div style={{ width: '30px', height: '30px', backgroundColor: '#34495e', marginLeft: '30px', border: '2px solid grey' }}></div>
                <span style={{ marginLeft: '15px', color: 'black', fontSize: '12px' }}>GX Class</span>

                <div style={{ width: '30px', height: '30px', backgroundColor: '#6c3483', marginLeft: '30px', border: '2px solid grey'}}></div>
                <span style={{ marginLeft: '15px', color: 'black', fontSize: '12px' }}>PT</span>

                <div style={{ width: '30px', height: '30px', backgroundColor: '#e67e22', marginLeft: '30px', border: '2px solid grey'}}></div>
                <span style={{ marginLeft: '15px', color: 'black', fontSize: '12px' }}>OB</span>


                <Col className="text-center">
          <DropDown clublist={clublist} dateselected={selectedDate} onClubChange={handleClubChange} className="dropdown-container" />
        </Col>


                 



            </Navbar.Brand>

            <Col className="text-right">
        <div className="custom-border"
          style={{ cursor: 'pointer' }}
          onClick={toggleCalendar}
          >Select a Date :   
          {selectedDate.toDateString()}

        </div>
        {isCalendarOpen && (
          <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            zIndex: 1,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}          >
            <Calendar
              onChange={handleDateChange}
              value={selectedDate}
              className='custom-calendar'
              />


          </div>


)}




      </Col>



      


        </Container>
      );
}

export default Header;