import React, { useState, useEffect } from 'react';
import './DropDown.css'; // Import your custom CSS for DropDown styling

function DropDown({ clublist, onClubChange, dateselected }) {
  const [selectedValue, setSelectedValue] = useState('');

  const handleSelection = (event) => {
    const selectedClubId = parseInt(event.target.value);
    setSelectedValue(selectedClubId);
    onClubChange(selectedClubId);
  };

  useEffect(() => {
    console.log('Selected club value:', selectedValue);
  }, [selectedValue]);

  return (
    <div className="dropdown-container">
      <select className="dropdown-select" onChange={handleSelection}>
        {clublist.map((club) => (
          <option key={club.id} value={club.id}>
            {club.clubname}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropDown;
