import React, { useState, useEffect, useRef } from 'react';
import { Card, CardGroup } from 'react-bootstrap';
import { FaSquare } from 'react-icons/fa';
import './Header.css'

function EventCard({ event, slots, eventId, eventTitle, style, onTitleChange, memberId }) {
  const [title, setTitle] = useState(event.title);
  const [typeId, setTypeId] = useState(event.typeId);
  const [isEdit, setIsEdit] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    setTitle(event.title);
    setTypeId(event.typeId);
  }, [event.title, event.typeId]);

   useEffect(() => {
    const handleDocumentClick = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {

        onTitleChange(memberId, eventId, title, typeId);
        console.log("evnts" + event.title)
        setIsEdit(false);
      
      }
    };

    if (isEdit) {
      document.addEventListener('click', handleDocumentClick);
    }
 
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isEdit, title, typeId, memberId, eventId, onTitleChange]); 

  const handleCardClick = (value) => {
    setIsEdit(!isEdit);
    //setIsEdit(true);
    setTypeId(value);
    setTitle(title);
  };

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const cardClickHandle = () => {
    onTitleChange(memberId, event.id, title, typeId);
  };

  let cardContent = <Card.Title style={{ color: 'white', fontSize: '16px'}}>{title}</Card.Title>;

  if (isEdit) {
    cardContent = <Card.Title ><input value={title} onChange={handleChange}/></Card.Title>;
  }

  const cardWithIconStyle = {
    ...style,
    position: 'relative',
  };

  const iconContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    backgroundColor: '#ecf0f1',
    

  };

  const iconStyle = {
    color: '#ff0000',
    backgroundColor: '#ecf0f1',
    borderRadius: '100%',
    fontSize: '18px'
  };

  return (
    <CardGroup onClick={cardClickHandle} ref={cardRef}>
      <Card className="ml-1 mt-1 custom-card" style={cardWithIconStyle}>
        <div style={iconContainerStyle}>
          <FaSquare style={{ color: 'black', marginLeft: '15px' }} onClick={() => handleCardClick(1)} />
          <FaSquare style={{ color: 'white', marginLeft: '15px' }} onClick={() => handleCardClick(2)} />
          <FaSquare style={{ color: '#34495e', marginLeft: '15px' }} onClick={() => handleCardClick(3)} />
          <FaSquare style={{ color: '#6c3483', marginLeft: '15px' }} onClick={() => handleCardClick(4)} />
          <FaSquare style={{ color: '#e67e22', marginLeft: '15px' }} onClick={() => handleCardClick(5)} />
        </div>
        <Card.Body>{cardContent}</Card.Body>
      </Card>
    </CardGroup>
  );
}

export default EventCard;
